import React from 'react'
import styled from 'styled-components'
import RemarkRenderer from 'components/RemarkRenderer'

// ===================================================
// STYLES
// ===================================================

const Footer = styled('footer')({
  position: 'relative',
  width: '100%',
  background: ({ theme }) => theme.colors.darkGray.color,
  color: ({ theme }) => theme.colors.gray.color,
})

const Content = styled('div')({
  width: '100%',
  maxWidth: 1600,
  margin: 'auto',
  padding: '64px 24px 96px',
  display: 'flex',
  justifyContent: 'space-between',
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    textAlign: 'center',
  },
})

const LeftColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const Heading = styled('div')({
  fontSize: 22,
  fontWeight: 'bold',
  marginBottom: 24,
  color: ({ theme }) => theme.colors.white.color,
})

const ContactInfo = styled('div')({
  fontSize: 18,
  lineHeight: '30px',
  margin: 0,
  fontFamily: 'Verdana, Roboto, Helvetica, Arial, sans-serif',
})

const RightColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  jusitfyContent: 'space-between',
  alignItems: 'flex-end',
  '@media (max-width: 600px)': {
    alignItems: 'center',
  },
})

const LinksContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  flex: 1,
  '@media (max-width: 600px)': {
    alignItems: 'center',
  },
})

const Link = styled('a')({
  display: 'block',
  fontSize: 18,
  lineHeight: '30px',
  textDecoration: 'underline',
  transition: 'color 225ms',
  '&:hover': {
    color: ({ theme }) => theme.colors.white.color,
  },
})

const Button = styled('button')({
  background: 'transparent',
  border: '1px solid',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: 16,
  padding: '12px 36px',
  cursor: 'pointer',
  transition: 'box-shadow 225ms',
  color: ({ theme }) => theme.colors.white.color,
  '&:hover': {
    boxShadow: '0 0 6px 2px #0005',
  },
})

// ===================================================
// COMPONENTS
// ===================================================

const StoryblokLandingPageFooter = ({
  data: { heading, contactInfo, links = [], button },
}) => (
  <Footer>
    <Content>
      <LeftColumn>
        <Heading>{heading}</Heading>
        <ContactInfo>
          <RemarkRenderer ast={contactInfo?.childMarkdownRemark?.htmlAst} />
        </ContactInfo>
      </LeftColumn>
      <RightColumn>
        <LinksContainer>
          {links.map(link => (
            <Link href={link.targetUrl} key={`footer-link-${link.label}`}>
              {link.label}
            </Link>
          ))}
        </LinksContainer>
        {button?.label && (
          <a href={button.targetUrl}>
            <Button>{button.label}</Button>
          </a>
        )}
      </RightColumn>
    </Content>
  </Footer>
)

export default StoryblokLandingPageFooter
